import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined'
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined'
import AccountMenu from './AccountMenu'
import { useAppContext } from 'context/AppContext/AppContext'
import LogoEnergizer from 'assets/icons/LogoEnergizer'
import AppBar from '@mui/material/AppBar'
import Menu from '@mui/material/Menu'
import i18next from 'i18next'
import MenuItem from '@mui/material/MenuItem'
import { useTranslation } from 'react-i18next'
import CardMedia from '@mui/material/CardMedia'
import flagPL from 'assets/images/flags/pl.png'
import flagEN from 'assets/images/flags/en.png'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
const isDev = process.env.REACT_APP_TYPE === 'development'

interface HeaderProps {
  handleSidebarToggle: () => void
}

const Header = ({ handleSidebarToggle }: HeaderProps): JSX.Element => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { changeTheme, isDarkMode } = useAppContext()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const { palette } = useTheme()
  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const cspUrls = [
    ...(isDev ? ['http://localhost:7000', 'http://localhost:7001'] : []),
    'https://api.energizerfun-beta.ds.plej.pl',
    'https://api.energizerfun-stage.ds.plej.pl',
    'https://api.trolls3.energizerfun.com'
  ]

  const csp = `
  default-src 'self';
  script-src 'self';
  style-src 'self';
  object-src 'none';
  form-action 'self';
  base-uri 'self';
  connect-src 'self' ${cspUrls.join(' ')};
  font-src 'self';
  frame-src 'self';
  img-src 'self' data: ${cspUrls.join(' ')};
  manifest-src 'self';
  media-src 'self';
  worker-src 'none';
  style-src-elem 'self' 'unsafe-inline';
  style-src-attr 'self' 'unsafe-inline';
`

  return (
    <>
      <Helmet defaultTitle="Energizer">
        <html lang={i18next.language} />
        <meta charSet="utf-8" />
        <title>Energizer</title>
        <link rel="icon" href="favicon.ico" />
        <meta name="viewport" content="width=device-width initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="description" content="Welcome to Energizer, your management system" />
        {!(process.env.REACT_APP_ENVIRONMENT === 'PROD') ? <meta name="robots" content="noindex, nofollow" /> : null}
        <meta
          httpEquiv="Content-Security-Policy"
          content={csp} />
      </Helmet>
      <AppBar position="fixed" color="inherit" sx={{ zIndex: 1250 }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleSidebarToggle}
            edge="start"
            sx={{ marginRight: 5 }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Link style={{ color: isDarkMode ? palette.common.white : palette.common.black }} to={'/dashboard'}><LogoEnergizer width={120} /></Link>
            <Toolbar>
              <IconButton onClick={handleClick}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined} color="inherit">
                <img src={i18next.language === 'pl' ? flagPL : flagEN} width={20} alt={i18next.language} />
              </IconButton>

              <Menu anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0
                    }
                  }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                {i18next.languages.map(lng => (
                  <MenuItem key={lng} onClick={async () => await i18next.changeLanguage(lng)}>
                    <CardMedia component="img" src={lng === 'pl' ? flagPL : flagEN} width={16} alt={i18next.language} sx={{
                      height: 16,
                      width: 16,
                      marginRight: '6px'
                    }} />
                    {lng === 'pl' ? t('language.polish') : t('language.english')}
                  </MenuItem>
                ))}
              </Menu>

              <IconButton onClick={changeTheme} color="inherit">
                {theme.palette.mode === 'dark' ? <DarkModeOutlinedIcon /> : <LightModeOutlinedIcon />}
              </IconButton>
              <AccountMenu />
            </Toolbar>
          </Box>
        </Toolbar>
      </AppBar>

    </>

  )
}

export default Header
